import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import {
  keyBy,
  map,
  uniq,
} from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaymentStatuses } from '../../../../common_lib/types/passInfo';
import useOrderPrice from '../../../../common_lib_front/hooks/useOrderPrice';
import { backendResponse } from '../../../../common_lib_front/types/backendResponse';
import CompanyInfo, {
  newCompanyInfo,
} from '../../../../common_lib_front/types/companyInfo';
import PassInfo, { PassTypeInfo, newPassInfo } from '../../../../common_lib_front/types/passInfo';
import { newVehicleInfo } from '../../../../common_lib_front/types/vehicleInfo';
import { backendClient } from '../../../../common_lib_front/utilities/BackendAPI';
import { useGetRegistration } from '../../../../hooks/useRegistration';
import { useSEtApplicationStatus } from '../../../../hooks/useSetApplicationStatus';
import { GET_PRICE_INFO } from '../registerVehicle/registerVehicleRequests';
import { registrationStepProps } from '../wrapper';
// import GuestInfo, { newGuestInfo } from '../../../common_lib_front/types/guestInfo';

const STEP_FOUR_QUERY = gql`
  query Step4Query($registrationId: String!) {
    getPassesByRegistrationWithPassInfo(registrationId: $registrationId) {
      success
      error
      data {
        passId
        number
        externalCredentialNumber
        startDate
        endDate
        status
        passInfoId
        passType
        addons
        paid
        passInfo {
          passApprovalRequire
        }

      }
    }
    getCompanyProfile {
      success
      error
      data {
        userId
        companyName
        altPhoneNumber
        businessPhone
        address
        city
        state
        zipCode
        emergencyContactName
        emergencyPhoneNumber
      }
    }
  }
`;
type STEP_FOUR_VARS = {
  registrationId: string,
}
type STEP_FOUR_RES = {
  getPassesByRegistrationWithPassInfo: backendResponse<{
    passId: string,
    number: number,
    startDate: string,
    endDate: string,
    status: string,
    passInfoId: string,
    passType: string,
    addons: string[],
    paid: boolean,
    passInfo: PassTypeInfo | null,
  }[]>,
  getCompanyProfile: backendResponse<{
    userId: string,
    companyName: string,
    altPhoneNumber: string,
    businessPhone: string,
    address: string,
    city: string,
    state: string,
    zipCode: string,
  }>
}

const STEP_FOUR_SUMBIT = gql`
  mutation Step4($registrationId: String!) {
    doStripeCharge (
      registrationId: $registrationId,
    ) {
      url
      error
      success
    }
  }
`;

const GET_VEHICLE = gql`
  query GetVehicle($passId: String!) {
    getVehicle(passId: $passId) {
      success
      error
      data {
        vehicleId
        make
        vehicleModel
        type
        color
        year
        licensePlate
        fleetNumber
        isRental
        licensePlateState
        primaryDriverName
        licensePrimaryDriver
      }
    }
  }
`;

// Change the registration step number
const DO_COMPLETE_STEPS = gql`
  mutation doCompleteStep($registrationId: String! $stepNumber: Float!) {
    editRegistrationStepNumber(
      stepNumber: $stepNumber
      registrationId: $registrationId
    ) {
      error
      success
    }
  }
`;

type DO_COMPLETE_STEPS_VARS = {
  registrationId: string;
  stepNumber: number;
}
type DO_COMPLETE_STEPS_RES = {
  editRegistrationStepNumber: backendResponse<undefined>
}

type useSecureCheckoutRes = {
  loading: boolean,
  companyInfo: CompanyInfo;
  registrationId: string;
  passes: Array<any>;
  redirect: string;
  submitHandler: () => void;
  errorMsg: string | undefined;
  selectedPayment: string;
  setSelectedPayment: (s: string) => void;
  isRenewal: boolean;
}
export default function useSecureCheckout(props: registrationStepProps): useSecureCheckoutRes {
  const { nextPath } = props;
  const [passes, setPasses] = useState<PassInfo[]>([]);
  const [redirect, setRedirect] = useState<string>('');
  const { registrationId } = useParams<{ registrationId: string }>();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>(newCompanyInfo());
  const [selectedPayment, setSelectedPayment] = useState<string>('Credit Card');
  const [doEditApplicationStatus] = useSEtApplicationStatus();
  const registration = useGetRegistration({ registrationId });
  const { totalPrice } = useOrderPrice(passes);
  const isFree = totalPrice === 0;

  const { refetch } = useQuery<
    STEP_FOUR_RES, STEP_FOUR_VARS
  >(STEP_FOUR_QUERY, {
    variables: {
      registrationId,
    },
    onCompleted: async (d) => {
      if (!d.getPassesByRegistrationWithPassInfo.success) {
        return;
      }

      const res = d.getPassesByRegistrationWithPassInfo.data?.map(
        (p) => newPassInfo(p as any),
      ) || [];
      Promise.all(
        res.map((element, idx: number) => backendClient
          .query({
            query: GET_VEHICLE,
            variables: { passId: element.passId },
          })
          .then((v) => {
            res[idx].vehicle = newVehicleInfo(v.data.getVehicle.data);
          })
          .catch((e) => {
            console.log(e);
          })),
      ).then(() => {
        // setPasses(res);
        setCompanyInfo(newCompanyInfo(d.getCompanyProfile.data));
      });

      // Get passes' prices by passInfoId
      const passInfoIds = uniq(
        map(res, 'passInfoId'),
      );
      try {
        const priceInfoRes = await Promise.all(
          map(passInfoIds, (id: string) => backendClient.query({
            query: GET_PRICE_INFO,
            variables: { passInfoId: id },
          })),
        );
        // Set price info to passes by passInfoId
        const priceInfoMap = keyBy(map(priceInfoRes, 'data.getPriceInfo.data'), 'passInfoId');
        res?.forEach((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.priceInfo = priceInfoMap[item.passInfoId];
          // eslint-disable-next-line no-param-reassign
          item.price = Number(item.priceInfo.price) || 0;
        });
      } catch (error: any) {
        console.log(error);
      }

      setPasses(res);
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [doSubmit, { error, loading: doSubmitLoading }] = useMutation(STEP_FOUR_SUMBIT, {
    variables: {
      registrationId,
    },
    onError: () => {
      if (process.env.REACT_APP_DEBUG === 'true') {
        // setRedirect(`/vendor/registration/${registrationId || 0}/receipt`);
        setRedirect(nextPath(registrationId));
      } else {
        refetch();
      }
    },
    onCompleted: (d) => {
      if (d.doStripeCharge.success) document.location.href = d.doStripeCharge.url;
    },
  });

  // Change the registration step to confirm registration is done
  const [doComplete] = useMutation<
  DO_COMPLETE_STEPS_RES, DO_COMPLETE_STEPS_VARS
  >(DO_COMPLETE_STEPS, {
    variables: {
      registrationId,
      stepNumber: props.stepNum + 1,
    },
  });

  const submitHandler = async () => {
    doEditApplicationStatus({
      variables: {
        status: 'pending-review',
        registrationId,
      },
    });

    if (
      (!registration.data?.getRegistration.data?.[0].renew
        && passes.every(
          (p) => p.paid === PaymentStatuses.paid || p.paid === PaymentStatuses.noCharge,
        ))
      || selectedPayment === 'Cash/Physical Check' || isFree
    ) {
      // Set the registation step to confirm registation is done
      doComplete();
      // setRedirect(`/vendor/registration/${registrationId}/receipt`);
      setRedirect(nextPath(registrationId));
    } else {
      doSubmit();
    }
  };

  return {
    loading: doSubmitLoading,
    companyInfo,
    registrationId,
    passes,
    redirect,
    submitHandler,
    errorMsg: error?.message,
    selectedPayment,
    setSelectedPayment,
    isRenewal: registration.data?.getRegistration.data?.[0]?.renew || false,
  };
}
